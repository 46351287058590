import React from 'react'
import NotFoundSection from 'src/components/not-found/not-found-section';
import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktFooter from 'src/interface/footers/mkt-footer';

const NotFoundPage = () => (
  <MktRoute title='Mile Hi Labs | Not Found'>
  	<MktNav />
    <main className='mvh-50'>
    	<NotFoundSection />
    </main>
    <MktFooter />
  </MktRoute>
)

export default NotFoundPage;
